import {Box, Button, Container, CssBaseline, TextField, Typography} from "@mui/material";
import {useContext, useState} from "react";
import AuthContext from "../../../contexts/AuthContext";

function LoginForm() {
    const {loginUser} = useContext(AuthContext);

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    const login = () => {
        loginUser(credentials.username, credentials.password);
    }

    const inputChanged = event => {
        setCredentials(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
        }));
    }

    const keyPress = event => {
        if (event.keyCode === 13) {
            login(event);
        }
    }

    return (
        <>
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        component="h1"
                        variant="h5"
                    >
                        Mannen Weekend Spel
                    </Typography>
                    <Box noValidate sx={{mt: 1}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Gebruikersnaam"
                            name="username"
                            autoFocus
                            onChange={inputChanged}
                            onKeyDown={keyPress}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Wachtwoord"
                            type="password"
                            onChange={inputChanged}
                            onKeyDown={keyPress}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            onClick={login}
                        >
                            Inloggen
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default LoginForm;
