import {createContext, useContext, useState} from "react";
import AxiosContext from "./AxiosContext";
import NotificationsContext from "./NotificationsContext";
import {useNavigate} from "react-router-dom";

const GameContext = createContext(null);

export default GameContext;

export const GameProvider = ({children}) => {
    const [game, setGame] = useState(null);
    const [games, setGames] = useState([]);
    const [players, setPlayers] = useState([]);
    const [categories, setCategories] = useState([]);
    const [questions, setQuestions] = useState([]);
    const {
        getGameRequest,
        getGamesRequest,
        createGameRequest,
        getPlayersRequest,
        getCategoriesRequest,
        createQuestionRequest,
        startGameRequest,
        goToQuestionRequest,
        goToResultRequest,
        getQuestionsRequest,
    } = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    const getGame = (game_id, newAuthUser) => {
        const handleResponse = (response) => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getGameRequest(game_id, handleResponse, handleError, newAuthUser);
    }

    const getGames = (newAuthUser) => {
        const handleResponse = (response) => {
            setGames(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getGamesRequest(handleResponse, handleError, newAuthUser);
    }

    const createGame = (newGame) => {
        const handleResponse = () => {
            navigate("/home");
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        createGameRequest(newGame, handleResponse, handleError);
    }

    const resetGames = () => setGames([]);

    const getPlayers = (newAuthUser) => {
        const handleResponse = (response) => {
            setPlayers(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getPlayersRequest(handleResponse, handleError, newAuthUser);
    }

    const getCategories = (newAuthUser) => {
        const handleResponse = (response) => {
            setCategories(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getCategoriesRequest(handleResponse, handleError, newAuthUser);
    }

    const createQuestion = (question, resetQuestionData) => {
        const handleResponse = () => {
            resetQuestionData();
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0] === undefined ? "Internal server error" : error.response.data[0]);
        }

        createQuestionRequest(question, handleResponse, handleError);
    }

    const startGame = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        startGameRequest(gameId, handleResponse, handleError);
    }

    const goToQuestion = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        goToQuestionRequest(gameId, handleResponse, handleError);
    }

    const goToResult = gameId => {
        const handleResponse = response => {
            setGame(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        goToResultRequest(gameId, handleResponse, handleError);
    }

    const getQuestions = newAuthUser => {
        const handleResponse = (response) => {
            setQuestions(response.data);
        }
        const handleError = error => {
            enqueueErrorSnackbar(error.response.data[0]);
        }

        getQuestionsRequest(handleResponse, handleError, newAuthUser);
    }

    const contextData = {
        game,
        games,
        getGame,
        getGames,
        createGame,
        resetGames,
        players,
        getPlayers,
        categories,
        getCategories,
        createQuestion,
        startGame,
        goToQuestion,
        goToResult,
        questions,
        getQuestions,
    };

    return (
        <GameContext.Provider value={contextData}>
            {children}
        </GameContext.Provider>
    );
};