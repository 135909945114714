import Grid from '@mui/material/Grid';
import {Button, Divider, MenuItem, Select, Switch, TextField, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import {useNavigate} from "react-router-dom";
import GameContext from "../../contexts/GameContext";
import NotificationsContext from "../../contexts/NotificationsContext";

const DEFAULT_QUESTION = {
    category: "",
    text: "",
    answers: [
        {
            text: "",
            is_correct: true,
        },
        {
            text: "",
            is_correct: false,
        },
        {
            text: "",
            is_correct: false,
        },
        {
            text: "",
            is_correct: false,
        },
    ],
};

function CreateQuestion() {
    const {
        createQuestion,
        categories,
        getCategories,
    } = useContext(GameContext);
    const [newQuestion, setNewQuestion] = useState(DEFAULT_QUESTION);
    const [createNewCategory, setCreateNewCategory] = useState(false);
    const {authUser} = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    useEffect(() => {
        getCategories();
    }, []);

    const resetQuestionData = () => {
        setNewQuestion(DEFAULT_QUESTION);
        setCreateNewCategory(false);
    }

    const onCreateQuestion = () => {
        if (createNewCategory && newQuestion.category === "") enqueueErrorSnackbar("Insert category");
        else if (!createNewCategory && newQuestion.category === "") enqueueErrorSnackbar("Select a category");
        else if (newQuestion.text === "") enqueueErrorSnackbar("No question text");
        else if (newQuestion.answers.some(obj => obj.text === "")) enqueueErrorSnackbar("Fill in all the answers");
        else createQuestion(
            {
                ...newQuestion,
                createNewCategory,
                creator: authUser.user_id,
            }, resetQuestionData
        );
    }

    const handleCategoryButton = () => {
        setCreateNewCategory(prevCreateNewCategory => !prevCreateNewCategory);
        setNewQuestion(prevNewQuestion => ({
            ...prevNewQuestion,
            category: "",
        }));
    }

    const onSwitchChange = (event, index) => {
        if (event.target.checked) {
            setNewQuestion(prevNewQuestion => ({
                ...prevNewQuestion,
                answers: prevNewQuestion.answers.map((prevAnswer, i) =>
                    i === index ? {...prevAnswer, is_correct: true} : {...prevAnswer, is_correct: false}
                )
            }));
        }
    }

    return (
        <Grid
            container
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={3}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={3}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            Category
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                    >
                        <Grid container style={{justifyContent: "flex-end"}}>
                            <Grid item xs={9}>
                                {createNewCategory ? (
                                    <TextField
                                        style={{
                                            width: "70%",
                                        }}
                                        value={newQuestion.category}
                                        onChange={e => {
                                            if (e.target.value.length <= 20) {
                                                setNewQuestion(prevNewQuestion => ({
                                                    ...prevNewQuestion,
                                                    category: e.target.value
                                                }));
                                            }
                                        }}
                                    />
                                ) : (
                                    <Select
                                        style={{
                                            width: "70%",
                                        }}
                                        value={newQuestion.category}
                                        onChange={event => setNewQuestion(prevNewQuestion => ({...prevNewQuestion, category: event.target.value}))}
                                    >
                                        {categories.map(obj => (
                                            <MenuItem key={obj.id} value={obj.id}>{obj.description}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        style={{
                            alignContent: "center",
                        }}
                    >
                        {authUser.is_staff && (
                            <Button
                                style={{
                                    alignContent: "center",
                                }}
                                variant="contained"
                                onClick={handleCategoryButton}
                            >
                                {createNewCategory ? "Existing" : "New"}
                            </Button>
                        )}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            Question
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                    >
                        <TextField
                            style={{
                                width: "70%",
                            }}
                            value={newQuestion.text}
                            onChange={e => {
                                if (e.target.value.length <= 100) {
                                    setNewQuestion(prevNewQuestion => ({
                                        ...prevNewQuestion,
                                        text: e.target.value
                                    }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                            }}
                        />
                    </Grid>
                    {newQuestion.answers.map((answer, index) => (
                        <Grid key={index} container>
                            <Grid item xs={2}>
                                <Typography
                                    style={{
                                        height: "100%",
                                        alignContent: "center",
                                    }}
                                >
                                    Answer {index + 1}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={8}
                            >
                                <TextField
                                    style={{
                                        width: "90%",
                                    }}
                                    value={answer.text}
                                    onChange={e => {
                                        if (e.target.value.length <= 20) {
                                            setNewQuestion(prevNewQuestion => ({
                                                ...prevNewQuestion,
                                                answers: prevNewQuestion.answers.map((prevAnswer, i) =>
                                                    i === index ? {...prevAnswer, text: e.target.value} : prevAnswer
                                                )
                                            }));
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Switch
                                    checked={answer.is_correct}
                                    onChange={e => onSwitchChange(e, index)}
                                />
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/home")}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => onCreateQuestion()}
                        >
                            Create
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateQuestion;
