import {useContext} from "react";
import GameContext from "../../../contexts/GameContext";
import {getStatusComponent} from "./definitions";
import NotificationsContext from "../../../contexts/NotificationsContext";
import {useNavigate} from "react-router-dom";

function Caster() {
    const {
        game,
    } = useContext(GameContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    return (
        game && getStatusComponent(game.status.id, enqueueErrorSnackbar, navigate)
    );
}

export default Caster;
