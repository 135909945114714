import ReadyToStart from "./components/ready_to_start";
import ChoosingCategory from "./components/choosing_category";
import CategorySelected from "./components/category_selected";
import SidePlayersAnswering from "./components/side_players_answering";
import MainPlayerAnswering from "./components/main_player_answering";
import QuestionAnswered from "./components/question_answered";
import Result from "./components/result";

const STATUS_TO_COMPONENT_MAPPING = new Map([
    ["0", <ReadyToStart />],
    ["1", <ChoosingCategory />],
    ["2", <CategorySelected />],
    ["3", <SidePlayersAnswering />],
    ["4", <MainPlayerAnswering />],
    ["5", <QuestionAnswered />],
    ["6", <Result />],
]);

export const getStatusComponent = (status_id, enqueueErrorSnackbar, navigate) => {
    const component = STATUS_TO_COMPONENT_MAPPING.get(status_id);
    if (component === undefined) {
        enqueueErrorSnackbar("Game has an invalid status");
        navigate("/home");
    }
    return component;
}
