import Grid from '@mui/material/Grid';
import {useContext, useEffect} from "react";
import GameContext from "../../contexts/GameContext";
import {Button, Divider, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AxiosContext from "../../contexts/AxiosContext";
import {OpenInNew} from "@mui/icons-material";

function Home() {
    const {
        games,
        getGames,
    } = useContext(GameContext);
    const {authUser} = useContext(AxiosContext);

    const navigate = useNavigate();

    useEffect(() => {
        getGames();
    }, []);

    return (
        <Grid
            container
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={4}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        justifyContent: "center",
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography>
                            Games
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    {games.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography>
                                No games available
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid
                                container
                                style={{
                                    maxHeight: "50vh",
                                    overflowY: "auto",
                                    border: "1px solid black",
                                    padding: 10,
                                    rowGap: 10,
                                }}
                            >
                                {games.map((game, index) => (
                                    <Grid item xs={12} key={game.id}>
                                        <Grid
                                            container
                                            style={{
                                                cursor: "pointer",
                                                rowGap: 10,
                                            }}
                                            onClick={() => navigate("/game/" + game.id)}
                                        >
                                            <Grid item xs={3}>
                                                <Typography>{game.description}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>{game.status.description}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>{game.main_player.username}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {game.casters.some(obj => obj.id === authUser.user_id) && (
                                                    <Button
                                                        variant="contained"
                                                        endIcon={<OpenInNew/>}
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                            window.open("/main_screen/" + game.id);
                                                        }}
                                                    >
                                                        Screen
                                                    </Button>
                                                )}
                                            </Grid>
                                            {index !== games.length - 1 && (
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    {authUser.is_staff && (
                        <Grid item xs={12}>
                            <Divider
                                sx={{
                                    margin: "10px",
                                    bgcolor: "black",
                                }}
                            />
                        </Grid>
                    )}
                    {authUser.is_staff && (
                        <Grid item xs={4}>
                            <Button
                                variant="contained"
                                onClick={() => navigate("/create_game")}
                            >
                                Create game
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={authUser.is_staff ? 4 : 6}>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/questions")}
                        >
                            Questions
                        </Button>
                    </Grid>
                    <Grid item xs={authUser.is_staff ? 4 : 6}>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/create_question")}
                        >
                            Create question
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;
