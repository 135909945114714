import {Button} from "@mui/material";
import {useContext} from "react";
import GameContext from "../../../../contexts/GameContext";

function QuestionAnswered() {
    const {
        game,
        goToResult,
    } = useContext(GameContext);

    return (
        <Button
            variant="contained"
            onClick={() => goToResult(game.id)}
        >
            Go To Result
        </Button>
    );
}

export default QuestionAnswered;
