import {useContext, useEffect} from "react";
import GameContext from "../../contexts/GameContext";
import Grid from "@mui/material/Grid";
import Caster from "../game/caster/caster";
import {Button, Divider, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

function Questions() {
    const {
        questions,
        getQuestions,
    } = useContext(GameContext);

    const navigate = useNavigate();

    useEffect(() => {
        getQuestions();
    }, []);

    return (
        <Grid
            container
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={3}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        justifyContent: "center",
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography>
                            Questions
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    {questions.map((question, _) => (
                        <Grid item xs={12}>
                            <Typography>
                                {question.text}
                            </Typography>
                        </Grid>
                    ))}
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            style={{
                                width: "100%",
                            }}
                            onClick={() => navigate("/home")}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Questions;
