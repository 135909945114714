import {AuthProvider} from "./contexts/AuthContext";
import {AxiosProvider} from "./contexts/AxiosContext";
import {SnackbarProvider} from "notistack";
import {NotificationsProvider} from "./contexts/NotificationsContext";
import {GameProvider} from "./contexts/GameContext";

function Contexts({children}) {
    return (
        <SnackbarProvider maxSnack={5}>
            <NotificationsProvider>
                <AxiosProvider>
                    <GameProvider>
                        <AuthProvider>
                            {children}
                        </AuthProvider>
                    </GameProvider>
                </AxiosProvider>
            </NotificationsProvider>
        </SnackbarProvider>
    )
}

export default Contexts;
