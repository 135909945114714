import Grid from '@mui/material/Grid';
import {useContext, useEffect} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import {useParams} from "react-router-dom";
import GameContext from "../../contexts/GameContext";
import Caster from "./caster/caster";

function Game() {
    const {id} = useParams();
    const {authUser} = useContext(AxiosContext);
    const {
        game,
        getGame,
    } = useContext(GameContext);

    useEffect(() => {
        getGame(id);
    }, []);

    return (
        <Grid
            container
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            {game && game.casters.some(obj => obj.id === authUser.user_id) && (
                <Caster />
            )}
        </Grid>
    );
}

export default Game;
