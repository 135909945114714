import Grid from '@mui/material/Grid';
import {useContext, useEffect} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import {Navigate, useParams} from "react-router-dom";
import GameContext from "../../contexts/GameContext";
import {CircularProgress} from "@mui/material";

function MainScreen() {
    const {id} = useParams();
    const {authUser} = useContext(AxiosContext);
    const {
        game,
        getGame,
    } = useContext(GameContext);

    useEffect(() => {
        getGame(id);
    }, []);

    return (
        <Grid
            container
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            {!game ? <CircularProgress/> : (!game.casters.some(obj => obj.id === authUser.user_id) ? <Navigate to="/home"/> : (
                <Grid item xs={6}>
                    {game.description}
                </Grid>
            ))}
        </Grid>
    );
}

export default MainScreen;
