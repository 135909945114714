import Grid from '@mui/material/Grid';
import {Button, Divider, Switch, TextField, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import AxiosContext from "../../contexts/AxiosContext";
import {Navigate, useNavigate} from "react-router-dom";
import GameContext from "../../contexts/GameContext";
import NotificationsContext from "../../contexts/NotificationsContext";

function CreateGame() {
    const {
        createGame,
        players,
        getPlayers,
    } = useContext(GameContext);
    const [newGame, setNewGame] = useState({
        description: "",
        players: [],
        casters: [],
    });
    const {authUser} = useContext(AxiosContext);
    const {enqueueErrorSnackbar} = useContext(NotificationsContext);

    const navigate = useNavigate();

    useEffect(() => {
        getPlayers();
    }, []);

    const onSwitchChange = (event, player, type) => {
        setNewGame(prevNewGame => {
            const isChecked = event.target.checked;
            const updateArray = type === 'players' ? prevNewGame.players : prevNewGame.casters;
            const removeFromArray = type === 'players' ? prevNewGame.casters : prevNewGame.players;

            return {
                ...prevNewGame,
                [type]: isChecked
                    ? [...updateArray, player]
                    : updateArray.filter(obj => obj.id !== player.id),
                [type === 'players' ? 'casters' : 'players']: removeFromArray.filter(obj => obj.id !== player.id)
            };
        });
    }

    const onCreateGame = () => {
        if (newGame.description === "") enqueueErrorSnackbar("Fill in a description");
        else if (newGame.casters.length === 0) enqueueErrorSnackbar("Select at least one caster");
        else if (newGame.players.length < 2) enqueueErrorSnackbar("Select at least two players");
        else createGame(newGame);
    }

    return !authUser.is_staff ? <Navigate to="/home"/> : (
        <Grid
            container
            style={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                alignContent: "center",
            }}
        >
            <Grid
                item
                xs={3}
                style={{
                    borderRadius: 10,
                    border: "2px solid blue",
                    padding: 10,
                }}
            >
                <Grid
                    container
                    style={{
                        justifyContent: "space-between",
                        padding: 10,
                        rowGap: 10,
                    }}
                >
                    <Grid item xs={3}>
                        <Typography
                            style={{
                                height: "100%",
                                alignContent: "center",
                            }}
                        >
                            Description
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={9}
                    >
                        <TextField
                            style={{
                                width: "70%",
                            }}
                            value={newGame.description}
                            onChange={e => {
                                if (e.target.value.length <= 20) {
                                    setNewGame(prevNewGame => ({
                                        ...prevNewGame,
                                        description: e.target.value
                                    }));
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    {players.length === 0 ? (
                        <Grid item xs={12}>
                            <Typography>
                                No users available
                            </Typography>
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Grid
                                container
                                style={{
                                    maxHeight: "50vh",
                                    overflowY: "auto",
                                    border: "1px solid black",
                                    padding: 10,
                                    rowGap: 10,
                                }}
                            >
                                {players.map((player, index) => (
                                    <Grid item xs={12} key={player.id}>
                                        <Grid
                                            container
                                            style={{
                                                rowGap: 10,
                                            }}
                                        >
                                            <Grid item xs={6}>
                                                <Typography>{player.username}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>Player</Typography>
                                                <Switch
                                                    checked={newGame.players.some(obj => obj.id === player.id)}
                                                    onChange={e => onSwitchChange(e, player, "players")}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>Caster</Typography>
                                                <Switch
                                                    checked={newGame.casters.some(obj => obj.id === player.id)}
                                                    onChange={e => onSwitchChange(e, player, "casters")}
                                                />
                                            </Grid>
                                            {index !== players.length - 1 && (
                                                <Grid item xs={12}>
                                                    <Divider/>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: "10px",
                                bgcolor: "black",
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => navigate("/home")}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            onClick={() => onCreateGame()}
                        >
                            Create
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateGame;
